<template>
  <div
    id="layoutsPurchase"
    :class="{
      noBackground: '--no-background',
      noBackgroundSmall: '--no-background-small',
      isTransactionalProcess: '--is-transactional-process',
    }"
  >
    <GlobalLoader />

    <Navbar v-show="!showNavbar" :purchase-mode="isTransactionalProcess" />

    <main>
      <nuxt />
    </main>

    <Footer :purchase-mode="isTransactionalProcess" />
  </div>
</template>

<script>
// Components
import Footer from '@/components/Footer.vue'
import GlobalLoader from '@/components/GlobalLoader.vue'
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'LayoutsPurchase',
  scrollToTop: true,
  components: {
    Footer,
    GlobalLoader,
    Navbar,
  },
  computed: {
    isTransactionalProcess() {
      return (
        this.$route.name.includes('request-finance') || this.$route.name.includes('request-booking')
      )
    },
    showNavbar() {
      return (
        this.$route.name.includes('request-booking') || this.$route.name.includes('request-finance')
      )
    },
    noBackground() {
      return this.$route.name.includes('waiting-room')
    },
    noBackgroundSmall() {
      return this.$route.name.includes('request-finance')
    },
  },
  async beforeCreate() {
    await this.$store.dispatch('campaign/fetchData', this.$route.query)
  },
  async mounted() {
    await this.$store.dispatch('campaign/initializeStore')
    await this.$store.dispatch('auth/initializeStore')
    await this.$store.dispatch('searches/initializeStore')
  },
  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  },
}
</script>

<style lang="scss" scoped>
#layoutsPurchase {
  min-height: 100vh;
  margin-bottom: -4.5rem;
  background: $c-white;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  &.--no-background {
    background: none;
  }

  @include size-s {
    background: linear-gradient(
      to bottom,
      $c-white,
      $c-white 72.33%,
      $c-neutral-100 27.67%,
      $c-neutral-100
    );

    &.--no-background-small {
      background: none;
    }
  }

  @include size-m {
    &.--is-transactional-process {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
</style>
