<template>
  <div id="layoutsError">
    <GlobalLoader />
    <RequestFinanceToken v-if="error.statusCode == 401 && error.type == 'auto_login'" />
    <Error v-else :error-type="error.type" :error-code="error.statusCode" />
  </div>
</template>

<script>
// Components
import Error from '@/components/layouts/Error.vue'
import GlobalLoader from '@/components/GlobalLoader.vue'
import RequestFinanceToken from '@/components/layouts/RequestFinanceToken.vue'

export default {
  name: 'LayoutsError',
  scrollToTop: true,
  layout: 'defaultWithoutNewsletter',
  components: {
    Error,
    GlobalLoader,
    RequestFinanceToken,
  },
  props: {
    error: {
      type: Object,
      default: () => ({
        statusCode: 500,
        message: '',
        type: 'default',
      }),
    },
  },
  async beforeCreate() {
    await this.$store.dispatch('campaign/fetchData', this.$route.query)
  },
  async mounted() {
    await this.$store.dispatch('campaign/initializeStore')
    await this.$store.dispatch('auth/initializeStore')
    await this.$store.dispatch('searches/initializeStore')
  },
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })

    return {
      title: this.$t('pages.error.head.title'),
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
      },
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.error.head.meta.description'),
        },
        ...i18nHead.meta,
      ],
      link: [...i18nHead.link],
    }
  },
}
</script>
