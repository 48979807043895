<template>
  <div
    v-show="type !== 'hidden'"
    :class="[
      'input-wrapper',
      'f-left',
      {
        'skeleton-loader': skeletonLoader,
        ...(size && { [`input-wrapper--${size}`]: size }),
      },
    ]"
  >
    <label v-if="label" :for="`input-${id}`" class="f-size-14 f-500">
      {{ label }}
    </label>

    <div
      v-click-outside="onClickOutside"
      :class="[
        'input',
        {
          'input--focused': focused,
          'input--filled': isFilled,
          'input--error': error || (maxlength && $attrs.value?.length > maxlength),
          'input--disabled': disabled,
        },
      ]"
    >
      <div
        v-if="prependIcon"
        data-cy="prepend-icon"
        class="icon-wrapper icon-wrapper--prepend"
        :class="{ clickable: prependClickable }"
        @click="onPrependClick()"
      >
        <Icon :name="prependIcon" size="1.25rem" />
      </div>
      <input
        :id="`input-${id}`"
        v-bind="$attrs"
        :aria-errormessage="error ? `input-${id}-error` : null"
        :aria-invalid="!!error"
        :aria-label="label"
        :autoComplete="autoComplete"
        :disabled="disabled"
        :invalid="!!error"
        :max="max"
        :min="min"
        :name="name"
        :placeholder="placeholder"
        :type="type !== 'password' ? type : passwordIcon ? 'text' : 'password'"
        @input="onInput($event.target.value)"
        @blur="onBlur()"
        @focus="onFocus()"
        @click="onClick()"
      />
      <div v-if="error" data-cy="error-icon" class="icon-wrapper icon-wrapper--error">
        <Icon name="warning" size="1.25rem" />
      </div>
      <div
        v-if="appendIcon || type === 'password'"
        data-cy="append-icon"
        class="icon-wrapper icon-wrapper--append"
        :class="{ clickable: appendClickable || type === 'password' }"
        @click="onAppendClick()"
      >
        <Icon :name="appendIcon ? appendIcon : passwordIconName" size="1.25rem" />
      </div>
      <div
        v-if="$attrs.value && showClearAction && type !== 'password'"
        data-cy="cross-icon"
        class="icon-wrapper icon-wrapper--remove icon-wrapper--clickable"
        @click="onClear()"
      >
        <Icon name="cancel" filled size="1.25rem" />
      </div>
    </div>

    <p class="input-wrapper__feedback f-size-12 s-mt-0 s-mb-0">
      <span v-if="error" :id="`input-${id}-error`" class="error" role="alert">
        {{ error }}
      </span>
      <span v-else-if="hint" class="hint">{{ hint }}</span>

      <span
        v-if="maxlength"
        :class="[
          'length-counter',
          {
            error:
              error &&
              (($attrs?.required === '' && $attrs.value?.length === 0) ||
                $attrs.value?.length > maxlength),
          },
        ]"
      >
        {{ `${$attrs.value?.length}/${maxlength}` }}
      </span>
    </p>
  </div>
</template>

<script>
// Plugins
import clickOutside from '@/plugins/click-outside'

// Components
import Icon from '@/components/Icon.vue'

// Other
const SIZES = ['s', 'm']

export default {
  name: 'Input',
  components: {
    Icon,
  },
  directives: {
    clickOutside,
  },
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    autoComplete: {
      type: String,
      default: null,
      required: false,
    },
    prependIcon: {
      type: String,
      default: null,
      required: false,
    },
    prependClickable: {
      type: Boolean,
      default: false,
      required: false,
    },
    appendIcon: {
      type: String,
      default: null,
      required: false,
    },
    appendClickable: {
      type: Boolean,
      default: false,
      required: false,
    },
    size: {
      type: String,
      validator: (value) => SIZES.includes(value),
      default: 'm',
    },
    min: {
      type: Number,
      required: false,
      default: null,
    },
    max: {
      type: Number,
      required: false,
      default: null,
    },
    minlength: {
      type: Number,
      required: false,
      default: null,
    },
    maxlength: {
      type: Number,
      required: false,
      default: null,
    },
    skeletonLoader: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      id: null,
      focused: false,
      passwordIcon: '',
      showClearAction: false,
    }
  },
  computed: {
    passwordIconName() {
      return this.passwordIcon ? 'visibility' : 'visibility_off'
    },
    isFilled() {
      return this.$attrs.value !== ''
    },
  },
  methods: {
    onFocus() {
      this.$emit('focus')
      this.focused = true
    },
    onBlur() {
      this.$emit('blur')
      this.focused = false
    },
    onClick() {
      this.showClearAction = true
    },
    onClickOutside() {
      this.showClearAction = false
    },
    onInput(value) {
      this.$emit('input', value)
    },
    onClear() {
      this.$emit('input', '')
    },
    onPrependClick() {
      this.$emit('prependClick')
    },
    onAppendClick() {
      if (this.type === 'password') {
        this.passwordIcon = !this.passwordIcon
      } else {
        this.$emit('onPrependClick')
      }
    },
  },
  watch: {
    '$attrs.value': {
      handler(newVal, _) {
        if (this.focused && newVal?.length) {
          this.showClearAction = true
        }
      },
      deep: true,
    },
  },
  created() {
    this.id = this._uid
  },
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  --height: 3.25rem;

  min-height: 6rem;
  margin-top: 2rem;

  &--s {
    min-height: 5rem;

    .input {
      --height: 2.5rem;
    }
  }

  .icon {
    pointer-events: none;
  }

  .input {
    display: flex;
    align-items: center;
    height: var(--height);
    margin: 0.25rem 0 0;
    padding: 1rem;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    border: solid 0.125rem $c-neutral-700;
    border-radius: 0.5rem;
    outline: none;
    background-color: $c-neutral-300;
    gap: 0.25rem;

    input {
      appearance: none;
      flex: 1;
      width: 100%;
      height: fit-content;
      border: none;
      outline: none;
      background-color: transparent !important;
      color: $c-neutral-900;

      &::placeholder {
        color: $c-neutral-800;
      }
    }

    &:not(.input--disabled) {
      &:hover {
        border-color: $c-neutral-900;
        background-color: $c-neutral-500;

        input {
          color: $text-primary;
        }
      }

      &.input--focused input,
      &.input--filled input {
        color: $text-primary;
      }

      &.input--filled {
        border-color: $c-neutral-900;
      }

      &.input--focused {
        border-color: $c-primary-500;
      }

      &.input--error {
        padding-left: 1rem;
        border-color: $c-error;

        input {
          color: $c-error;
        }
      }
    }

    &--disabled {
      padding-right: 3rem;
      border-color: $disabled-01;
      background-color: $disabled-01;
      background-image: url(../assets/icons/lock_tbr.svg);
      background-repeat: no-repeat;
      background-position: calc(100% - 1rem);
      color: $disabled-02;

      input {
        cursor: no-drop;
      }

      cursor: no-drop;
    }

    .icon-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $c-neutral-900;

      &--error :deep(.icon) {
        transform: translateY(-0.125rem);
        color: $c-error !important;
      }

      &--remove {
        justify-self: flex-end;
      }

      &--clickable {
        cursor: pointer;
      }
    }
  }

  &__feedback {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.25rem 0.25rem 0 1rem;

    .error {
      color: $c-error;
    }

    .hint {
      color: $c-info;
    }

    .length-counter {
      margin-left: auto;
    }
  }
}
</style>
