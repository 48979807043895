<template>
  <div id="layoutsEmpty">
    <GlobalLoader />

    <main>
      <nuxt />
    </main>

    <Notifications />
  </div>
</template>

<script>
// Components
import GlobalLoader from '@/components/GlobalLoader.vue'
import Notifications from '@/components/Notifications.vue'

export default {
  name: 'LayoutsEmpty',
  scrollToTop: true,
  components: {
    GlobalLoader,
    Notifications,
  },
  async beforeCreate() {
    await this.$store.dispatch('campaign/fetchData', this.$route.query)
  },
  async mounted() {
    await this.$store.dispatch('campaign/initializeStore')
    await this.$store.dispatch('auth/initializeStore')
    await this.$store.dispatch('searches/initializeStore')
  },
  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  },
}
</script>
