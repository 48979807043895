<template>
  <div
    :class="[
      'loader',
      {
        'loader--absolute': absolute,
        'loader--fixed': fixed,
        'loader--reduced': reduced,
        'loader--state-loading': loading,
      },
    ]"
  >
    <div>
      <span
        v-if="reduced || userBrowser === 'safari'"
        :class="[
          'css-loader',
          {
            'css-loader--light': theme === 'light',
            'css-loader--dark': theme === 'dark',
          },
        ]"
      />
      <video v-else autoplay loop muted playsinline width="128" height="128" aria-hidden="true">
        <source :src="`/videos/loader/${theme}.webm`" type="video/webm" />
        Your browser does not support the video tag.
      </video>
    </div>

    <slot />
  </div>
</template>

<script>
import { getBrowserName } from '@/helpers/environment'

export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
    absolute: {
      type: Boolean,
      required: false,
      default: false,
    },
    reduced: {
      type: Boolean,
      required: false,
      default: false,
    },
    theme: {
      type: String,
      required: false,
      default: 'light',
      validate: (value) => ['light', 'dark'].includes(value),
    },
  },
  data() {
    return {
      userBrowser: '',
    }
  },
  mounted() {
    this.userBrowser = getBrowserName()
  },
}
</script>

<style lang="scss" scoped>
.loader {
  min-width: 8rem;
  min-height: 8rem;

  > div {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: unset;
    left: unset;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s ease;
    gap: 0;
  }

  video {
    width: 8rem;
    height: 8rem;
  }

  &:not(.loader--fixed) {
    position: relative;
  }

  &--fixed {
    position: fixed !important;
    z-index: 9999;
  }

  &--absolute {
    position: absolute !important;
    z-index: 99;
  }

  &--fixed,
  &--absolute {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    > div {
      background-color: rgba(#000, 60%);
    }
  }

  &:not(.loader--state-loading) {
    opacity: 0 !important;
    pointer-events: none;
  }
}

.css-loader {
  position: relative;
  width: 3rem;
  height: 3rem;
  animation: rotate 1s linear infinite;
  border-radius: 50%;

  &--light {
    --color: #fff;
  }

  &--dark {
    --color: #1e1932;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    animation: prix-clip-fix 2s linear infinite;
    border: 0.3125rem solid var(--color);
    border-radius: 50%;
    inset: 0;
  }

  &::after {
    inset: 0.5rem;
    transform: rotate3d(90, 90, 0, 180deg);
    border-color: var(--color);
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes prix-clip-fix {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    75%,
    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
  }
}
</style>
