<template>
  <Loader v-if="loading" fixed loading />
</template>

<script>
// Components
import Loader from '@/components/Loader.vue'

export default {
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
    }
  },
  mounted() {
    this.$nuxt.$on('loadingStart', this.showSpinner)
    this.$nuxt.$on('loadingFinish', this.hideSpinner)
  },
  beforeDestroy() {
    this.$nuxt.$off('loadingStart', this.showSpinner)
    this.$nuxt.$off('loadingFinish', this.hideSpinner)
  },
  methods: {
    showSpinner() {
      this.loading = true
    },
    hideSpinner() {
      this.loading = false
    },
  },
}
</script>
