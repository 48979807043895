<template>
  <Modal
    :label="title"
    :open="open"
    class="sign-form"
    data-cy="sign-form"
    width="55.5rem"
    v-on="$listeners"
    @close="() => $emit('close')"
  >
    <header class="sign-form__header">
      <p>
        <template v-if="isSignUpForm">
          <span class="bold">
            {{ $t('modals.sign_form.header.register_or_login[0]') }}
          </span>
          {{ $t('modals.sign_form.header.register_or_login[1]') }}
          <span class="f-as-link" @click="switchForm()">
            {{ $t('modals.sign_form.header.register_or_login[2]') }}
          </span>
          {{ $t('modals.sign_form.header.register_or_login[3]') }}
        </template>
        <template v-else>
          <span class="bold">
            {{ $t('modals.sign_form.header.login_or_register[0]') }}
          </span>
          {{ $t('modals.sign_form.header.login_or_register[1]') }}
          <span class="f-as-link" @click="switchForm()">
            {{ $t('modals.sign_form.header.login_or_register[2]') }}
          </span>
          {{ $t('modals.sign_form.header.login_or_register[3]') }}
        </template>
      </p>

      <ul>
        <li
          v-for="(item, index) in $t('modals.sign_form.header.description')"
          :key="index"
          v-html="item"
        />
      </ul>
    </header>

    <Notification
      v-if="error"
      :message="$t(`messages.error.${error}.message`)"
      :title="
        $te(`messages.error.${error}.title`) ? $t(`messages.error.${error}.title`) : undefined
      "
      displayed
      error
      inline
      class="sign-form__error"
      @close="() => (error = '')"
    />

    <!-- Sign up -->
    <ValidationObserver v-if="isSignUpForm" v-slot="{ validate }">
      <form class="signup-form__form" @submit.prevent="onSignUp(validate)">
        <div class="signup-form__form__fields">
          <ValidationProvider
            v-slot="{ touched, errors }"
            :skip-if-empty="false"
            name="Email"
            rules="required|email"
          >
            <Input
              v-model="signUpFormState.registration.email"
              :error="touched ? errors[0] : null"
              :label="`${$t('modals.sign_form.form.email')}`"
              :placeholder="$t('modals.sign_form.form.placeholder_email')"
              append-icon="email"
              data-cy="signup-email"
              name="email"
              required
              type="email"
            />
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ touched, errors }"
            :name="$t('modals.sign_form.form.name')"
            :skip-if-empty="false"
            rules="required|max:40"
          >
            <Input
              v-model="signUpFormState.profile.name"
              :error="touched ? errors[0] : null"
              :label="`${$t('modals.sign_form.form.name')}`"
              :maxlength="40"
              :placeholder="$t('modals.sign_form.form.placeholder_name')"
              data-cy="signup-name"
              name="name"
              required
            />
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ touched, errors }"
            :name="$t('modals.sign_form.form.last_name')"
            :skip-if-empty="false"
            rules="required|max:80"
          >
            <Input
              v-model="signUpFormState.profile.last_name"
              :error="touched ? errors[0] : null"
              :label="`${$t('modals.sign_form.form.last_name')}`"
              :maxlength="80"
              :placeholder="$t('modals.sign_form.form.placeholder_last_name')"
              data-cy="signup-last-name"
              name="last_name"
              required
            />
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ touched, errors }"
            :name="$t('modals.sign_form.form.second_last_name')"
            :skip-if-empty="false"
            rules="max:80"
          >
            <Input
              v-model="signUpFormState.profile.second_last_name"
              :error="touched ? errors[0] : null"
              :hint="$t('modals.sign_form.form.hint_second_last_name')"
              :label="`${$t('modals.sign_form.form.second_last_name')}`"
              :maxlength="80"
              :placeholder="$t('modals.sign_form.form.placeholder_second_last_name')"
              name="second_last_name"
            />
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ touched, errors }"
            :skip-if-empty="false"
            :name="$t('modals.sign_form.form.phone')"
            :rules="{
              required: true,
              regex: /^(\+34|0034|34)?[6789]\d{8}$/,
            }"
          >
            <Input
              v-model="signUpFormState.profile.phone"
              :error="touched ? errors[0] : null"
              :hint="$t('modals.sign_form.form.hint_phone')"
              :label="`${$t('modals.sign_form.form.phone')}`"
              :placeholder="$t('modals.sign_form.form.placeholder_phone')"
              append-icon="phone"
              name="phone"
              required
            />
          </ValidationProvider>

          <div />

          <ValidationProvider
            v-slot="{ touched, errors }"
            :skip-if-empty="false"
            :name="$t('modals.sign_form.form.password')"
            rules="required|min:6"
          >
            <Input
              v-model="signUpFormState.registration.password"
              :error="touched ? errors[0] : null"
              :label="`${$t('modals.sign_form.form.password')}`"
              :placeholder="$t('modals.sign_form.form.placeholder_password')"
              :hint="$t('modals.sign_form.form.password_hint')"
              name="password"
              type="password"
              required
            />
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ touched, errors }"
            :skip-if-empty="false"
            :name="$t('signup.form.confirm_password')"
            :rules="`password:@${$t('signup.form.password')}`"
          >
            <Input
              v-model="confirm_password"
              data-cy="confirm-password-input"
              :error="touched ? errors[0] : null"
              :label="`${$t('signup.form.confirm_password')}`"
              :placeholder="$t('signup.form.placeholder_password')"
              :auto-complete="`new-password`"
              :name="$t('signup.form.confirm_password')"
              type="password"
              required
            />
          </ValidationProvider>
        </div>

        <div class="sign-form__legals">
          <ValidationProvider
            v-slot="{ errors }"
            :skip-if-empty="false"
            :rules="{ required: { allowFalse: false } }"
            :name="$t('global.terms_of_service.label')"
          >
            <Checkbox
              v-model="signUpFormState.registration.terms_of_service_accepted"
              :error="errors[0]"
              name="terms_of_service_accepted"
              terms-of-service
            />
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            :skip-if-empty="true"
            :name="$t('global.commercial_information_consent.label')"
          >
            <Checkbox
              v-model="signUpFormState.profile.commercial_information_consented"
              name="communication_accepted"
              :rules="{ required: { allowFalse: true } }"
              :error="errors[0]"
              :label="$t('global.commercial_information_consent.text')"
            />
          </ValidationProvider>
        </div>

        <button v-show="false" ref="submitSignup" />
      </form>
    </ValidationObserver>

    <!-- Sign in -->
    <ValidationObserver v-else v-slot="{ validate }">
      <form class="signin-form__form" @submit.prevent="onSignIn(validate)">
        <div class="signin-form__form__fields">
          <ValidationProvider
            v-slot="{ touched, errors }"
            :name="$t('modals.sign_form.form.email')"
            :skip-if-empty="false"
            rules="required|email"
            class="signin-form__form__fields__container"
          >
            <Input
              v-model="signInFormState.email"
              :error="touched ? errors[0] : null"
              data-cy="email-input"
              name="email"
              type="email"
              required
              :label="$t('modals.sign_form.form.email')"
              :placeholder="$t('modals.sign_form.form.email_placeholder')"
              class="signin-form__form__fields__container__input"
            />
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ touched, errors }"
            :name="$t('modals.sign_form.form.password')"
            :skip-if-empty="false"
            rules="required|min:6"
            class="signin-form__form__fields__container"
          >
            <Input
              v-model="signInFormState.password"
              :error="touched ? errors[0] : null"
              data-cy="password-input"
              name="password"
              type="password"
              required
              :label="$t('modals.sign_form.form.password')"
              :placeholder="$t('modals.sign_form.form.password_placeholder')"
              class="signin-form__form__fields__container__input"
            />
          </ValidationProvider>
        </div>

        <div class="sign-form__legals">
          <Checkbox v-model="signInFormState.rememberMe">
            {{ $t('modals.sign_form.form.remember_me') }}
          </Checkbox>

          <NuxtLink :to="localePath('password-recovery-request')" class="f-as-link">
            {{ $t('modals.sign_form.form.password_forgot') }}
          </NuxtLink>
        </div>

        <button v-show="false" ref="submitLogin" />
      </form>
    </ValidationObserver>

    <template v-if="isSignUpForm" v-slot:actions>
      <Button
        type-theme="primary-light"
        :label="$t('modals.sign_form.signup_submit')"
        @click="$refs.submitSignup.click()"
      />
      <p class="signup-form__form__actions__switch-login">
        {{ $t('modals.sign_form.signin_title') }}
        <span class="f-as-link" @click="switchForm()">{{ $t('modals.sign_form.signin_cta') }}</span>
      </p>
    </template>
    <template v-else v-slot:actions>
      <Button
        type-theme="primary-light"
        :label="$t('modals.sign_form.signin_submit')"
        @click="$refs.submitLogin.click()"
      />
      <p class="signin-form__form__actions__switch-signup">
        {{ $t('modals.sign_form.signup_title') }}
        <span class="f-as-link" @click="switchForm()">{{ $t('modals.sign_form.signup_cta') }}</span>
      </p>
    </template>
  </Modal>
</template>

<script>
// Dependencies
import { mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// Components
import Modal from '@/components/Modal.vue'
import Input from '@/components/Input.vue'
import Button from '@/components/Button.vue'
import Checkbox from '@/components/Checkbox.vue'
import Notification from '@/components/Notification.vue'

// Other
const TYPES = ['default', 'alerts', 'saved_searches', 'favourites']

export default {
  name: 'ModalsSign',
  components: {
    ValidationObserver,
    ValidationProvider,
    Modal,
    Input,
    Button,
    Checkbox,
    Notification,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'default',
      validator: (value) => TYPES.includes(value),
    },
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      // By default, the register form is selected. Otherwise, the login form
      isSignUpForm: true,
      signInFormState: {
        email: '',
        password: '',
        rememberMe: false,
      },
      signUpFormState: {
        registration: {
          email: '',
          password: '',
          terms_of_service_accepted: false,
        },
        profile: {
          name: '',
          last_name: '',
          second_last_name: '',
          phone: '',
          commercial_information_consented: false,
          postal_code: null,
          meta: {
            origin_process: 'Registration',
            origin: this.$route.query?.utm_source || 'Website',
            medium: this.$route.query?.utm_medium || null,
            campaign: this.$route.query?.utm_campaign || null,
          },
        },
      },
      confirm_password: '',
      error: false,
    }
  },
  computed: {
    title() {
      if (this.type === 'alerts') {
        return this.$t('pages.alerts.cta.add')
      } else if (this.type === 'favourites') {
        return this.$t('pages.favourites.cta.add')
      } else if (this.type === 'saved_searches') {
        return this.$t('pages.saved_searches.cta.add')
      } else {
        return undefined
      }
    },
    ctaLabel() {
      if (this.type === 'alert') {
        return this.$t('modals.sign_form.button_create_and')
      } else {
        return this.$t('modals.sign_form.button_save_and')
      }
    },
    gdprConsents() {
      const tratamientoDatos = this.signUpFormState.registration.terms_of_service_accepted
      const gdprMarca = this.signUpFormState.profile.commercial_information_consented

      return {
        tratamientoDatos,
        gdprMarca,
        legalTratamientoDatos: this.$t('global.terms_of_service.text').join(' '),
        legalComunicaciones: this.$t('global.commercial_information_consent.text'),
      }
    },
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      register: 'auth/register',
      recallCustomer: 'auth/recallCustomer',
    }),
    switchForm() {
      this.isSignUpForm = !this.isSignUpForm
    },
    async onSignIn(validate) {
      this.error = ''

      await validate().then(async (isValid) => {
        if (!isValid) {
          this.error = 'invalid_fields'
          this.loading = false
        } else {
          try {
            // Sign-in attempt
            await this.login(this.signInFormState)

            // SEO
            this.$tracker.login()

            // Emit results
            this.$emit('submit')
          } catch (err) {
            this.error = 'service'
          }
        }
      })
    },
    async onSignUp(validate) {
      this.error = ''

      await validate().then(async (isValid) => {
        if (!isValid) {
          this.error = 'invalid_fields'
          this.loading = false
        } else {
          try {
            // Sign-up attempt
            if (this.signUpFormState.profile.second_last_name === '') {
              this.signUpFormState.profile.second_last_name = null
            }
            await this.register(this.signUpFormState.registration)
            await this.$customerService.updateCustomer(this.signUpFormState.profile)
            await this.$customerService.updateCustomerGdpr(this.gdprConsents)
            await this.recallCustomer()

            // SEO
            if (process?.browser && process?.env?.gtm_enabled) {
              this.$gtm.push({ event: 'signup-form-submit' })
            }
            this.$tracker.signUp()

            // Emit results
            this.$emit('submit')
          } catch (err) {
            this.error = 'service'
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.sign-form {
  .input-wrapper,
  .textarea-wrapper {
    margin-top: 0 !important;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    margin-bottom: 2.5rem;

    ul {
      margin: 0;
    }
  }

  &__error {
    margin-bottom: 2.5rem;
  }

  .signin-form__form {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__fields {
      display: flex;
      grid-row-gap: 1rem;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @include size-m-up {
        grid-column-gap: 1.5rem;
        flex-direction: row;
      }

      @include size-l-up {
        grid-column-gap: 2rem;
      }

      @include size-xxl-up {
        grid-column-gap: 2.5rem;
      }

      &__container {
        width: 100%;
      }
    }

    &__extras {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      gap: 1rem;

      &__remember {
        text-align: center;
      }
    }
  }

  .signup-form__form {
    &__fields {
      display: grid;
      grid-column-gap: 0;
      grid-row-gap: 1rem;
      grid-template-columns: 1fr;

      @include size-m-up {
        grid-column-gap: 1.5rem;
        grid-template-columns: 1fr 1fr;
      }

      @include size-xl-up {
        grid-column-gap: 2rem;
      }

      @include size-xxl-up {
        grid-column-gap: 2.5rem;
      }
    }
  }

  &__legals {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin-top: 2.5rem;

    :deep(.checkbox-wrapper) {
      margin-top: 0 !important;
    }
  }
}
</style>
