<template>
  <Modal
    v-if="!actAsModal || (actAsModal && open)"
    :enable-prevent-body-scroll="actAsModal"
    :label="$t('global.filter')[1]"
    :disabled="disabled"
    data-cy="filters"
    full-screen-on-tablet
    name="filters"
    open
    sticky-footer
    @close="$emit('close')"
  >
    <template v-slot:header>
      <Button
        v-show="Object.keys(filters).length > 0"
        :label="$t('filters.labels.clean_filters')"
        class="clean-filters-button"
        type-theme="inline-primary"
        @click="$emit('clearFilters')"
      />
    </template>

    <div ref="filters-body" class="filters-content" data-cy="filters-container">
      <!-- Driving licences -->
      <div
        v-if="isMotorbike && drivingLicensesOptions?.length"
        class="filter"
        data-cy="filter-driving-licences"
      >
        <p class="filter__label f-700 f-size-14">{{ $t('filters.labels.driving_licences') }}</p>

        <Checkbox
          v-for="option in drivingLicensesOptions"
          :key="`driving-licence-${option}`"
          v-model="formState.drivingLicenses[option]"
          @input="onFilter"
        >
          {{ $t(`filters.labels.${option}`) }}
        </Checkbox>
      </div>

      <!-- Make -->
      <div v-if="makeOptions?.length" class="filter filter-with-select" data-cy="filter-make">
        <p class="filter__label f-700 f-size-14">{{ $t('filters.labels.make') }}</p>

        <Select
          v-model="formState.makeSlug"
          :label="$t('filters.labels.choose_make')"
          :options="makeOptions"
          name="make"
          @input="onFilterByMake"
        />
      </div>

      <!-- Model  -->
      <div
        v-if="modelOptions?.length"
        class="filter filter-with-select"
        :class="{
          disabled: !formState.makeSlug,
        }"
        data-cy="filter-model"
      >
        <p class="filter__label f-700 f-size-14">{{ $t('filters.labels.model') }}</p>

        <Select
          v-model="formState.modelSlug"
          :disabled="!formState.makeSlug"
          :hint="!formState.makeSlug ? $t('filters.labels.choose_make_first') : undefined"
          :label="$t('filters.labels.choose_model')"
          :options="modelOptions"
          name="model"
          @input="onFilter"
        />
      </div>

      <!-- Vehicles on offer -->
      <div v-if="!hiddenFilters.includes('onOffer')" class="filter" data-cy="filter-bodywork">
        <p class="filter__label f-700 f-size-14">
          {{ $t('filters.labels.vehicles_on_offer') }}
        </p>

        <Checkbox v-model="formState.onOffer" data-cy="on-offer-check" @input="onFilter">
          {{ $t('filters.labels.show_only_offers') }}
        </Checkbox>
      </div>

      <!-- Bodyworks -->
      <div v-if="bodyworkOptions?.length" class="filter" data-cy="filter-bodywork">
        <p class="filter__label f-700 f-size-14">
          {{
            isMotorbike ? $t('filters.labels.bodyworks_motorcycle') : $t('filters.labels.bodyworks')
          }}
        </p>

        <Checkbox
          v-for="option in bodyworkOptions"
          :key="`bodywork-${option}`"
          v-model="formState.bodyworkIn[option]"
          @input="onFilter"
        >
          {{ $t(`filters.labels.${option}`) }}
        </Checkbox>
      </div>

      <!-- Registration date (year) -->
      <div
        v-if="(isKm0 || isVo) && registrationDateOptions?.length"
        class="filter filter-with-select"
        data-cy="filter-registration-date"
      >
        <p class="filter__label f-700 f-size-14">{{ $t('filters.labels.registration_date') }}</p>

        <Select
          v-model="formState.registrationDate"
          :label="$t('filters.labels.choose_registration_date')"
          :options="registrationDateOptions"
          @input="onFilter"
        />
      </div>

      <div
        v-if="isVo && (minKilometersOptions?.length || maxKilometersOptions?.length)"
        class="filter filter-with-select"
        data-cy="filter-km-range"
      >
        <p class="filter__label f-700 f-size-14">{{ $t('filters.labels.kilometers') }}</p>

        <Select
          v-if="minKilometersOptions?.length"
          v-model="formState.kilometresGte"
          data-cy="km-from"
          :options="minKilometersOptions"
          :label="$t('global.from')"
          @input="onFilterByMinKilometers"
        />
        <Select
          v-if="maxKilometersOptions?.length"
          v-model="formState.kilometresLte"
          data-cy="km-to"
          :options="maxKilometersOptions"
          :label="$t('global.to')"
          @input="onFilterByMaxKilometers"
        />
      </div>

      <!-- Price range: financing price and installment price -->
      <div class="filter filter-with-select" data-cy="filter-price">
        <p class="filter__label f-700 f-size-14">{{ $t('filters.labels.price') }}</p>

        <NavbarScrollable
          v-if="!hiddenFilters.includes('financingPrice')"
          data-cy="explore-prices"
          :options="priceTypeOptions"
          :selection="pricingFilterType"
          theme="tertiary"
          @submit="(value) => onChangePricingFilterType(value)"
        />

        <div class="prices">
          <template v-if="pricingFilterType === 'total'">
            <Select
              v-model="formState.discountedPriceCentsGte"
              :options="lowestPriceOptions"
              name="price-from"
              :label="$t('global.from')"
              @input="onFilter"
            />
            <Select
              v-model="formState.discountedPriceCentsLte"
              :options="highestPriceOptions"
              name="price-to"
              :label="$t('global.to')"
              @input="onFilter"
            />
          </template>
          <template v-else>
            <Select
              v-model="formState.instalmentPriceCentsGte"
              :options="lowestPriceOptions"
              name="price-from"
              :label="$t('global.from')"
              @input="onFilter"
            />
            <Select
              v-model="formState.instalmentPriceCentsLte"
              :options="highestPriceOptions"
              name="price-to"
              :label="$t('global.to')"
              @input="onFilter"
            />
          </template>
        </div>
      </div>

      <!-- Fuel types -->
      <div
        v-if="fuelTypeOptions?.length || powertrainTypeOptions?.length"
        class="filter"
        data-cy="filter-fuel-type"
      >
        <p class="filter__label f-700 f-size-14">{{ $t('filters.labels.motor') }}</p>

        <Checkbox
          v-for="option in fuelTypeOptions"
          v-show="!((isMotorbike && option === 'diesel') || (isMotorbike && option === 'hybrid'))"
          :key="option"
          v-model="formState.fuelTypeIn[option]"
          @input="onFilter"
        >
          {{ $t(`filters.labels.${option}`) }}
        </Checkbox>

        <Checkbox
          v-for="option in powertrainTypeOptions"
          v-show="!((isMotorbike && option === 'diesel') || (isMotorbike && option === 'hybrid'))"
          :key="option"
          v-model="formState.powertrainTypeIn[option]"
          @input="onFilter"
        >
          {{ $t(`filters.labels.${option}`) }}
        </Checkbox>
      </div>

      <template v-if="isMotorbike">
        <!-- Cubic capacity -->
        <div
          v-if="
            formState.fuelTypeIn?.gasoline ||
            (!formState.fuelTypeIn?.gasoline && !formState.powertrainTypeIn?.electric)
          "
          class="filter"
        >
          <p class="filter__label f-700 f-size-14">{{ $t('filters.labels.cubic_capacity') }}</p>
          <RadioGroup
            v-model="formState.cubicCapacity"
            :options="cubicCapacityOptions"
            as-column
            name="cubic_capacity"
            @input="onFilter"
          />
        </div>

        <!-- Electric power -->
        <div
          v-if="
            formState.powertrainTypeIn?.electric ||
            (!formState.powertrainTypeIn?.electric && !formState.fuelTypeIn?.gasoline)
          "
          class="filter"
        >
          <p class="filter__label f-700 f-size-14">{{ $t('filters.labels.electric_power') }}</p>
          <RadioGroup
            v-model="formState.batteryMaxPower"
            :options="batteryMaxPowerOptions"
            as-column
            name="electric_power"
            @input="onFilter"
          />
        </div>
      </template>

      <!-- Transmission types -->
      <div v-if="transmissionTypeOptions?.length" class="filter" data-cy="filter-transmission-type">
        <p class="filter__label f-700 f-size-14">{{ $t('filters.labels.transmission') }}</p>
        <Checkbox
          v-for="option in transmissionTypeOptions"
          :key="option"
          v-model="formState.transmissionTypeIn[option]"
          @input="onFilter"
        >
          {{ $t(`filters.labels.${option}`) }}
        </Checkbox>
      </div>

      <!-- Environmental labels -->
      <div v-if="environmentalLabelsOptions?.length" class="filter">
        <p class="filter__label f-700 f-size-14">{{ $t('filters.labels.environmental_labels') }}</p>
        <span class="f-size-14">{{ $t('filters.labels.choose_environmental_labels') }}</span>
        <ul>
          <li v-for="option in environmentalLabelsOptions" :key="option">
            <EnvironmentalLabel
              :label="option"
              :selected="formState.environmentalLabels[option]"
              @click="onFilterByEnvironmentalLabel(option)"
            />
          </li>
        </ul>
      </div>
    </div>

    <template v-slot:actions>
      <Button type-theme="primary-light" size="large" class="filter-button" @click="onSubmit">
        {{ submitButtonLabel }}
      </Button>
    </template>
  </Modal>
</template>

<script>
// Dependencies
import { mapGetters, mapActions } from 'vuex'

// Helpers & mixins
import checkViewportSize from '@/mixins/checkViewportSize'
import {
  modelsOptionsWithoutYear,
  kilometersRange,
  fuelTypes,
  powertrains,
  drivingLicenses,
  transmissionTypes,
  cubicCapacities,
  batteryMaxPowers,
  priceOptions,
  carTotalPriceIntervals,
  carInstallmentPriceIntervals,
  motorbikeTotalPriceIntervals,
  motorbikeInstallmentPriceIntervals,
  getFitersState,
} from '@/helpers/catalog'

// Components
import Button from '@/components/Button.vue'
import Checkbox from '@/components/Checkbox.vue'
import EnvironmentalLabel from '@/components/EnvironmentalLabel.vue'
import Modal from '@/components/Modal.vue'
import NavbarScrollable from '@/components/NavbarScrollable.vue'
import RadioGroup from '@/components/RadioGroup.vue'
import Select from '@/components/Select.vue'

export default {
  name: 'CatalogFilters',
  components: {
    Button,
    Checkbox,
    EnvironmentalLabel,
    Modal,
    NavbarScrollable,
    RadioGroup,
    Select,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
      required: true,
      default: null,
    },
    vehicleType: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    hiddenFilters: {
      type: Array,
      required: false,
      default: () => [],
    },
    makes: {
      type: Array,
      required: true,
    },
    models: {
      type: Array,
      required: false,
      default: () => [],
    },
    vehiclesCount: {
      type: [Number, undefined],
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      actAsModal: true,
      batteryMaxPowerOptions: [],
      cubicCapacityOptions: [],
      drivingLicensesOptions: [],
      fuelTypeOptions: [],
      highestPriceOptions: [],
      lowestPriceOptions: [],
      makeOptions: [],
      maxKilometersOptions: [],
      minKilometersOptions: [],
      modelOptions: [],
      powertrainTypeOptions: [],
      priceTypeOptions: [],
      pricingFilterType: 'total',
      registrationDateOptions: [],
      transmissionTypeOptions: [],
      formState: {},
    }
  },
  mixins: [checkViewportSize],
  computed: {
    ...mapGetters({
      bodyworkOptions: 'catalog/getBodyworks',
      environmentalLabelsOptions: 'catalog/getEnvironmentalLabels',
      highestinstallmentPrice: 'catalog/getHighestinstallmentPrice',
      highestPrice: 'catalog/getHighestPrice',
      lowestinstallmentPrice: 'catalog/getLowestinstallmentPrice',
      lowestPrice: 'catalog/getLowestPrice',
      getModels: 'catalog/getModels',
    }),
    isMove() {
      return this.vehicleType === 'car' && this.category === 'move'
    },
    isVn() {
      return this.vehicleType && this.category === 'vn'
    },
    isVo() {
      return (this.vehicleType && this.category === 'vo') || this.isMove
    },
    isKm0() {
      return this.vehicleType && this.category === 'km0'
    },
    isMotorbike() {
      return ['moto', 'motorbike', 'motorcycle'].includes(this.vehicleType)
    },
    submitButtonLabel() {
      if (this.isSizeLUp) {
        return this.$t(`filters.submit_n_${this.isMotorbike ? 'motorbike' : 'car'}_label`, {
          number: this.vehiclesCount,
        })
      } else {
        return this.$t('filters.labels.submit')
      }
    },
    allPriceRange() {
      const isTotalFilterPriceSelected = this.pricingFilterType === 'total'

      try {
        const options =
          this.isMotorbike && isTotalFilterPriceSelected
            ? motorbikeTotalPriceIntervals
            : this.isMotorbike && !isTotalFilterPriceSelected
            ? motorbikeInstallmentPriceIntervals
            : !this.isMotorbike && isTotalFilterPriceSelected
            ? carTotalPriceIntervals
            : carInstallmentPriceIntervals

        if (options[0][0] !== null) {
          options.unshift([null, this.$t('filters.labels.any')])
        }

        return options
      } catch {
        return undefined
      }
    },
  },
  methods: {
    ...mapActions({
      setModelsByMake: 'catalog/setModelsByMake',
    }),
    onFilter() {
      if (this.isSizeLUp) {
        this.onSubmit()
      }
    },
    onSubmit() {
      this.$emit('submit', this.formState)
    },
    onResize() {
      if (window.innerWidth >= 1024) {
        this.actAsModal = false

        // Release body scroll
        const scrollY = document.body.style.top
        document.body.style.position = ''
        document.body.style.top = ''
        document.body.style.right = ''
        document.body.style.left = ''
        document.body.style.bottom = ''
        window.scrollTo(0, parseInt(scrollY || '0') * -1)
      } else {
        this.actAsModal = true
      }
    },
    async onFilterByMake() {
      this.formState.modelSlug = undefined

      if (this.formState.makeSlug) {
        const matchedMake = this.makes.filter((make) => make[0] === this.formState.makeSlug)

        let eventName
        if (this.isMotorbike) {
          eventName = 'view-brand-page-motorbike'
        } else if (this.isVn) {
          eventName = 'view-brand-page-newcar'
        } else if (this.isKm0) {
          eventName = 'view-brand-page-km0'
        } else if (this.isVo) {
          eventName = 'view-brand-page-vo'
        }

        this.$gtm.push({
          event: eventName,
          make: matchedMake[0][1],
        })
      }

      if (this.isSizeLUp) {
        this.onSubmit()
      } else {
        await this.setModelsByMake(this.formState.makeSlug)
        this.modelOptions = modelsOptionsWithoutYear(this.$i18n, this.getModels)
      }
    },
    onFilterByEnvironmentalLabel(option) {
      this.formState.environmentalLabels[option] = !this.formState.environmentalLabels[option]

      if (this.isSizeLUp) {
        this.onSubmit()
      }
    },
    onFilterByMinKilometers() {
      if (!this.formState.kilometresGte) {
        this.formState.kilometresGte = undefined
      } else {
        this.formState.kilometresGte = parseInt(this.formState.kilometresGte)
      }

      if (this.isSizeLUp) {
        this.onSubmit()
      }
    },
    onFilterByMaxKilometers() {
      if (!this.formState.kilometresLte) {
        this.formState.kilometresLte = undefined
      } else {
        this.formState.kilometresLte = parseInt(this.formState.kilometresLte)
      }

      if (this.isSizeLUp) {
        this.onSubmit()
      }
    },
    onChangePricingFilterType(value) {
      this.pricingFilterType = value

      this.formState.discountedPriceCentsGte = undefined
      this.formState.instalmentPriceCentsGte = undefined
      this.formState.discountedPriceCentsLte = undefined
      this.formState.instalmentPriceCentsLte = undefined
      this.lowestPriceOptions = this.allPriceRange
      this.highestPriceOptions = this.allPriceRange
    },
    setup() {
      // Setup form state
      this.formState = getFitersState(this.filters, this.$i18n, this.$store)

      // Setup filters options
      // Driving licenses
      this.drivingLicensesOptions = drivingLicenses

      // Make and model
      this.makeOptions = [[null, this.$t('filters.labels.all')[1]], ...this.makes]

      const makeSlug = this.formState?.makeSlug
      if (makeSlug) {
        this.modelOptions = this.models
      } else {
        this.modelOptions = [[null, this.$t('filters.labels.all')[0]]]
      }

      // Registration date year
      const year = new Date().getFullYear()
      this.registrationDateOptions = []
      this.registrationDateOptions.push([null, this.$t('filters.labels.registration_date')])
      this.registrationDateOptions.push([year, year])
      this.registrationDateOptions.push([year - 1, year - 1])
      this.registrationDateOptions.push([year - 2, year - 2])
      this.registrationDateOptions.push([year - 3, year - 3])

      // Kilometres range
      if (this.isVo) {
        const allKmsRange = kilometersRange.filter((km) => {
          return km[0]
        })

        let options

        if (!this.formState.kilometresLte) {
          options = allKmsRange
        } else {
          options = kilometersRange.filter((km) => {
            if (km[0] < this.formState.kilometresLte) {
              return km
            }
          })
        }

        this.minKilometersOptions = [[null, this.$t('filters.labels.all')[0]], ...options]

        if (!this.formState.kilometresGte) {
          options = allKmsRange
        } else {
          options = kilometersRange.filter((km) => {
            if (km[0] > this.formState.kilometresGte) {
              return km
            }
          })
        }

        this.maxKilometersOptions = [[null, this.$t('filters.labels.all')[0]], ...options]
      }

      // Price type options
      this.pricingFilterType =
        this.formState.instalmentPriceCentsGte || this.formState.instalmentPriceCentsLte
          ? 'installment'
          : 'total'

      this.priceTypeOptions = priceOptions(this.$i18n)

      if (this.allPriceRange) {
        let selectedGtePrice
        try {
          selectedGtePrice =
            this.formState.discountedPriceCentsGte || this.formState.instalmentPriceCentsGte
        } catch {
          selectedGtePrice = undefined
        }

        this.lowestPriceOptions = this.allPriceRange

        if (selectedGtePrice) {
          this.highestPriceOptions = this.allPriceRange.filter(
            (x) => x[0] > selectedGtePrice || x[0] === null,
          )
        } else {
          this.highestPriceOptions = this.allPriceRange
        }

        let selectedLtePrice
        try {
          selectedLtePrice =
            this.formState?.discountedPriceCentsLte || this.formState.instalmentPriceCentsLte
        } catch {
          selectedLtePrice = undefined
        }

        if (selectedLtePrice) {
          this.lowestPriceOptions = this.allPriceRange.filter(
            (x) => x[0] < selectedLtePrice || x[0] === null,
          )
        }
      }

      // Fuel type
      this.fuelTypeOptions = fuelTypes

      // Powertrain type
      this.powertrainTypeOptions = powertrains

      // Transmission type
      this.transmissionTypeOptions = transmissionTypes

      // Cubic capcity
      this.cubicCapacityOptions = cubicCapacities.map((value) => {
        return {
          value,
          label: this.$t(`filters.labels.${value}`),
        }
      })

      // Electric power (motorbikes)
      this.batteryMaxPowerOptions = batteryMaxPowers.map((value) => {
        return {
          value,
          label: this.$t(`filters.labels.${value}`),
        }
      })
    },
  },
  watch: {
    filters: {
      handler() {
        this.setup()
      },
    },
  },
  created() {
    this.setup()
  },
  mounted() {
    // Setup listeners
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  destroyed() {
    window.addEventListener('resize', this.onResize)
  },
}
</script>

<style lang="scss">
.filters-modal {
  @include size-l-up {
    position: relative !important;
    z-index: 1 !important;
    width: auto !important;
    height: auto !important;
    overflow: auto !important;
    background: none !important;

    .modal__header,
    .modal__main__actions {
      display: none !important;
    }

    .modal__main__content,
    .modal-content-wrapper,
    .modal {
      min-height: unset !important;
      padding: 0 !important;
    }

    .modal {
      padding: 1rem 1rem 1.5rem !important;
      border: 0.0625rem solid $c-neutral-500 !important;
      border-radius: 0.5rem !important;
    }
  }
}
</style>

<style scoped lang="scss">
.filters-modal {
  .filters-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
      margin-top: 0 !important;
    }

    .filter {
      &:not(.filter-with-select) + .filter:not(.filter-with-select),
      & + .filter-with-select {
        margin-top: 1.5rem;
      }

      &.filter-with-select + .filter {
        margin-top: 0.25rem;
      }

      &__label {
        margin: 0 0 1rem;
      }

      &:last-child {
        @include size-m {
          padding-bottom: 1rem;
        }
      }

      :deep(.checkbox-wrapper) {
        margin-top: 0.5rem;
      }

      .select-wrapper {
        margin: 0;
      }

      .prices {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 0.5rem;
        color: $c-dark-black;
        font-family: 'Inter', Arial, Helvetica, sans-serif;
        font-size: $font-size-base;
        line-height: 1.25rem;
      }

      ul {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        align-items: flex-end;
        margin: 0;
        margin-top: 0.75rem;
        padding: 0;
        list-style: none;
        gap: 0.75rem;

        :deep(.icon) {
          width: 2rem;
          height: 2rem;
        }
      }

      :deep(.swiper) {
        overflow: hidden !important;

        .swiper-wrapper {
          transform: none !important;
        }
      }
    }
  }

  .modal__main__actions .button {
    width: 100%;
  }

  @media (width >= 768px) and (width <= 1023px) {
    :deep(.modal-content-wrapper) {
      width: 50%;
      margin-left: auto;
    }
  }
}
</style>
