export default ({ app }) => {
  if (process.client) {
    app.router.beforeEach((to, from, next) => {
      if (window.$nuxt) {
        window.$nuxt.$emit('loadingStart')
      }
      next()
    })

    app.router.afterEach(() => {
      if (window.$nuxt) {
        window.$nuxt.$emit('loadingFinish')
      }
    })
  }
}
