<template>
  <div id="layoutsFavouritesAndSavedSearches">
    <GlobalLoader />

    <Navbar />

    <TabbedLayout
      data-cy="saved-searches-page"
      :title="currentTab.label"
      :tabs="tabs"
      :current-tab="currentTab.key"
      class="tabs"
    >
      <main class="container container--np">
        <nuxt />
      </main>
    </TabbedLayout>

    <Footer />
  </div>
</template>

<script>
// Components
import Footer from '@/components/Footer.vue'
import GlobalLoader from '@/components/GlobalLoader.vue'
import Navbar from '@/components/Navbar.vue'
import TabbedLayout from '@/components/layouts/Tabbed.vue'

export default {
  name: 'LayoutsFavouritesAndSavedSearches',
  scrollToTop: true,
  components: {
    Footer,
    GlobalLoader,
    Navbar,
    TabbedLayout,
  },
  computed: {
    tabs() {
      return [
        {
          key: this.localePath('favourites'),
          label: this.$t('pages.favourites.cta.label'),
          url: this.localePath('favourites'),
          icon: 'favorite_border',
          title: this.$t('pages.favourites.head.title'),
        },
        {
          key: this.localePath('saved-searches'),
          label: this.$t('pages.saved_searches.cta.label'),
          url: this.localePath('saved-searches'),
          icon: 'bookmark_border',
          title: this.$t('pages.saved_searches.head.title'),
        },
      ]
    },
    currentTab() {
      return this.tabs?.find((tab) => this.$route.path === tab.key) || undefined
    },
  },
  async beforeCreate() {
    await this.$store.dispatch('campaign/fetchData', this.$route.query)
  },
  async mounted() {
    await this.$store.dispatch('campaign/initializeStore')
    await this.$store.dispatch('auth/initializeStore')
    await this.$store.dispatch('searches/initializeStore')
  },
  head() {
    return {
      title: this.currentTab?.title,
    }
  },
}
</script>

<style lang="scss" scoped>
#layoutsFavouritesAndSavedSearches .tabs :deep(main) {
  min-height: calc(100vh - var(--navbar-height) - 9rem - 36.125rem);
}
</style>
